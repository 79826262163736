.hook-noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
/*-------------------
     Breakpoints
--------------------*/
/* Responsive */
.ps__rail-y {
  z-index: 10 !important;
}
.ps__rail-y:hover {
  background: inherit !important;
}
.crm-leftMenu {
  background: #1b2337;
}
@media (min-width: 767px) {
  .crm-leftMenu {
    box-shadow: 3px 0 3px rgba(0, 0, 0, 0.1), 3px 0 3px rgba(0, 0, 0, 0.14);
  }
}
@media (max-width: 767px) {
  .crm-leftMenu {
    visibility: hidden;
    display: block;
    position: absolute;
    width: 0;
    min-height: 100vh;
    height: 100%;
    z-index: 1;
    transform: translate3d(-100%, 0%, 0);
    overflow: hidden;
  }
}
.crm-leftMenu_visible {
  width: 240px;
  visibility: visible;
  transition: transform 0.2s ease;
  transform: translate3d(0, 0, 0);
  overflow-y: auto;
  z-index: 1001;
}
.crm-leftMenu__pusher {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100vw);
  height: 100vh;
  background: #1b2337;
  z-index: 1000;
  opacity: 0.4;
  overflow: hidden;
}
@media (min-width: 767px) {
  .crm-leftMenu {
    visibility: visible;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 45px 1fr 40px;
    grid-row-start: 1;
    grid-row-end: 3;
  }
}
.crm-leftMenu__logo {
  background: #00abd1;
  height: 64px;
  display: grid;
  justify-content: center;
  align-content: center;
}
@media (min-width: 767px) {
  .crm-leftMenu__logo {
    height: 45px;
  }
}
.crm-leftMenu__logo_item {
  color: #fff;
  font-weight: 500;
  font-size: 1.3em;
  height: 48px;
  width: 70px;
}
.crm-leftMenu__logo_item > img {
  object-fit: contain;
  height: 48px;
  width: 70px;
}
.crm-leftMenu__scrollBox {
  width: 100% !important;
  max-height: calc(100vh - 45px - 40px);
  height: calc(100vh - 45px - 40px);
}
.crm-leftMenu__scrollBoxMobile {
  width: 100% !important;
  max-height: calc(100vh - 64px);
  height: calc(100vh - 64px);
  overflow-y: auto;
}
.crm-leftMenu__menu {
  background: #1b2337 !important;
  width: 100% !important;
  position: relative;
  margin: 0 !important;
  padding: 1rem 0;
}
.crm-leftMenu__menu:before {
  content: "";
  background-repeat: no-repeat !important;
  background-size: cover !important;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.09;
}
.crm-leftMenu__menu_withPlus:hover .crm-leftMenu__menu_withPlusIcon {
  visibility: visible;
}
.crm-leftMenu__menu_withPlusIcon {
  visibility: hidden;
  position: absolute;
  top: 4px;
  right: 15px;
}
.crm-leftMenu__menu_withPlusIcon:hover {
  color: #006400;
}
.crm-leftMenu__menu_header {
  background: #1b2337;
  color: #fff !important;
  margin: 0 !important;
  padding-top: 15px !important;
  padding-bottom: 12px !important;
  font-size: 0.875em !important;
}
.crm-leftMenu__menu_collapsed {
  max-width: 71px !important;
  overflow-y: auto;
  max-height: calc(100vh - 45px - 55px);
  height: calc(100vh - 45px - 55px);
}
.crm-leftMenu__menu_collapsed > * > .transition {
  font-size: 1.2em !important;
}
.crm-leftMenu__menu_collapsed > div > .item {
  padding: 10px 1px !important;
  word-wrap: break-word;
  min-width: 69px !important;
  height: 71px !important;
}
.crm-leftMenu__menu > .item {
  color: #fff !important;
}
.crm-leftMenu__menu > .item.active {
  color: #00abd1 !important;
}
.crm-leftMenu__menu > .item > .menu > .item {
  color: #CCCCCC !important;
  margin-left: 30px;
}
.crm-leftMenu__menu > .item > .menu > .item.active {
  color: #00abd1 !important;
}
.crm-leftMenu__close {
  position: absolute;
  top: 12px;
  left: 8px;
}
@media (min-width: 767px) {
  .crm-leftMenu__close {
    visibility: hidden;
    display: none;
  }
}
.crm-leftMenu__expandSwitch {
  color: white;
  display: none;
}
@media (min-width: 767px) {
  .crm-leftMenu__expandSwitch {
    display: inline-block;
  }
}
.crm-leftMenu__mobileMenu {
  background: #f5f6fa;
  text-align: center;
  padding: 5px;
}
.crm-leftMenu__mobileMenu_avatar {
  display: flex;
  justify-content: center;
  margin: 0.5rem;
}
